import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {galleryUser, sendPrivateMessage, likeUserProfile, unlikeUserProfile, loggedUserData, doesConversationExist} from "../../actions";
import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';
import {Link} from "react-router-dom";

import PopPop from 'react-poppop';
import Membership from "./settings/Membership";
import ReactPlayer from "react-player";

class userProfile extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {
            isLiked: null,
            userLoaded: null,
            showUserPhoto: false,
            selectedPhoto: {},
            messagePopupOpen: false,
            conversationLoaded: false,
            conversationExists: false,
            hideName: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        let data = {
            participantId: this.props.match.params.participantId,
            userId: this.props.loggedUser.id
        };
        this.props.galleryUser(data);
        if (this.props.loggedUser === undefined) {
            this.props.loggedUserData(this.props.loggedUserId).then(() => this._isMounted && this.setState({userLoaded: true}));
        }
        this.props.doesConversationExist(this.props.loggedUserId, this.props.match.params.participantId).then((res) => {
                this._isMounted && this.setState({conversationLoaded: true});
                if (res === 200) {
                    this._isMounted && this.setState({conversationExists: true});
                }
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isLiked === null && parseInt(this.props.user.id) === parseInt(this.props.match.params.participantId)) {
            this._isMounted && this.setState({isLiked: this.props.user.likes.like});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    renderError({error, touched}) {
        if (touched && error) {
            return <div className="form-error-display-user-profile">{error}</div>
        }
    }

    renderInput = ({ input, meta}) => {
        return (
            <span>
                <textarea
                    {...input}
                    placeholder="Write a message..."
                    id="message-content"
                />
                {this.renderError(meta)}
            </span>
        );
    };

    onSubmit = formValues => {
        let data = {
            participantId: this.props.match.params.participantId,
            userId: this.props.loggedUser.id
        };
        this.props.sendPrivateMessage(data, formValues).then(() => {
            this._isMounted && this.setState({messagePopupOpen: false, conversationExists: true})
        });
    };

    onClickSelectPhoto = photo => {
        this._isMounted && this.setState({hideUserProfile: true, showUserPhoto: true, selectedPhoto: photo});
    };

    renderPhotos = () => {
        return this.props.user.photos.map((p) => {
            return (
                <div className="carousel-cell" key={p.url}>
                    <img
                        alt="User"
                        className="carousel-cell-image"
                        onClick={() => this.onClickSelectPhoto(p)}
                        src={p.bigUrl}
                    />
                </div>

            );
        })
    };

    renderVideo(user) {
        return (
            <div className="carousel-cell">
                <ReactPlayer
                    url={user.video.url}
                    className="carousel-cell-image"
                    muted={false}
                    controls={true}
                    file={{
                        file: {
                            forceHLS: true
                        },
                        attributes: {
                            poster: user.video.thumbnailUrl
                        }
                    }}
                />
            </div>
        )
    }

    Carousel = () => {
        if (this.props.user.photos.length > 0) {
            let length = this.props.user.photos.length;
            let hasVideo = !!(this.props.user.video);
            return (
                <Flickity
                    className={'carousel'}
                    elementType={'div'} // default 'div'
                    options={{
                        cellAlign: 'left',
                        contain: true,
                        on: {
                            select: (index) => {
                                if (hasVideo) {
                                    if (index === length) {
                                        this._isMounted && this.setState({hideName: true})
                                    } else {
                                        this._isMounted && this.setState({hideName: false})
                                    }
                                }
                            }
                        }
                    }}
                >
                    {this.renderPhotos()}
                    {(this.props.user.video) ? this.renderVideo(this.props.user) : ''}
                </Flickity>
            )
        } else {
            return (
                <span className="carousel-cell">
                    <img
                        alt="User"
                        className="carousel-cell-image"
                        src="/app/images/placeholder.png"
                    />
                </span>
            )
        }
    };

    renderAboutMe() {
        if (this.props.user.aboutMe) {
            return (
                <div>
                    <div className="fwb">About Me</div>
                    <div
                        className="user--aboutMe-text"
                        dangerouslySetInnerHTML={{__html: this.props.user.aboutMe}}
                    />
                </div>
            )
        }
    }

    onClickUnlikeUserProfile = () => {
        this.props.unlikeUserProfile(this.props.loggedUserId, this.props.user.id);
        this._isMounted && this.setState({isLiked: false});
    };

    onClickLikeUserProfile = () => {
        this.props.likeUserProfile(this.props.loggedUserId, this.props.user.id);
        this._isMounted && this.setState({isLiked: true});
    };

    renderLikeHeart() {
        if (this.state.isLiked) {
            return (
                <span
                    className="user--fixed-bottom-area--favorite"
                    onClick={() => this.onClickUnlikeUserProfile()}
                >
                    <i className="icon-heart-full icon-is-liked"/>
                </span>
            )
        } else {
            return (
                <span
                    className="user--fixed-bottom-area--favorite"
                    onClick={() => this.onClickLikeUserProfile()}
                >
                    <i className="icon-heart icon-not-liked"/>
                </span>
            )
        }
    }

    closePopupMembership = () => {
        if (this.state.messagePopupOpen) {
            this.setState({messagePopupOpen: false})
        }
    };

    renderSendMessagePopupContent = () => {
        if (this.props.loggedUser.subscriptions.self === null && this.props.loggedUser.subscriptions.both === null && this.props.loggedUser.sex !== 'female') {
            return <Membership popupOpen={this.state.messagePopupOpen} closePopupMembership={() => this.closePopupMembership()}/>
        }
        else {
            return(
                <PopPop
                    open={this.state.messagePopupOpen}
                    position="centerCenter"
                    closeBtn={true}
                    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                    onClose={() => this.setState({messagePopupOpen: false})}
                >
                    <div className="popup-area-margin">
                        <div className="popup-block">
                            <div className="popup-content-wrapper">
                                <div style={{fontSize: '22px', fontWeight: 'bold'}}>Send a message!</div>
                            <form className="area-centered--padded message-area" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                <Field name="content" component={this.renderInput} />
                                <button
                                    className="user--fixed-bottom-area--send-button"
                                >
                                    <i className="icon-plane"/>
                                </button>
                            </form>
                            </div>
                        </div>
                    </div>
                </PopPop>
            )
        }
    };

    renderSendMessageButton() {
        if (this.state.conversationLoaded && this.state.conversationExists) {
            return (
                <Link to={`/conversation/${this.props.match.params.participantId}`}>
                    <span
                        className="user--fixed-bottom-area--button fwb"
                        onClick={() => this._isMounted && this.setState({messagePopupOpen: true})}
                    >
                        Send message
                    </span>
                </Link>
            )
        } else if (this.state.conversationLoaded) {
            return (
                <span
                    className="user--fixed-bottom-area--button fwb"
                    onClick={() => this._isMounted && this.setState({messagePopupOpen: true})}
                >
                Send message
            </span>
            )
        }
    }

    render() {
        if (this.state.userLoaded && this.state.loggedUser.photos.length === 0) {
            return (<div/>);
        }

        if (this.props.user.name && parseInt(this.props.user.id) === parseInt(this.props.match.params.participantId)) {
            let isNameHidden = (this.state.hideName) ? 'hidden' : '';
                return (
                    <div>
                        <div>
                            <div style={{position: 'relative'}}>
                                <div className={isNameHidden}>
                                    <div className="user--name fwb">{this.props.user.name}</div>
                                    <div className="user--location">
                                        { this.props.user.location ?  this.props.user.location.fullName : '' }
                                    </div>
                                </div>
                                <div className="carousel">
                                    {this.Carousel()}
                                </div>
                            </div>
                            <div className="user--info">
                                <div style={{marginTop:'19px'}}>
                                    {this.renderAboutMe()}
                                </div>
                            </div>

                            <div className="user--fixed-expander" />

                            <div className="user--fixed-bottom-area">
                                <div className="area-centered">
                                    <div className="area-centered--padded">
                                        {this.renderSendMessageButton()}
                                        {this.renderLikeHeart()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PopPop
                            open={this.state.showUserPhoto}
                            position="centerCenter"
                            closeBtn={true}
                            contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                            onClose={() => this.setState({showUserPhoto: false})}
                        >
                            <img
                                alt="User"
                                src={this.state.selectedPhoto.bigUrl}
                            />
                        </PopPop>
                        {this.renderSendMessagePopupContent()}
                    </div>
                );
        }

        return (
            <div className="loader">Loading...</div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.galleryUser,
        loggedUserId: state.login.userId,
        loggedUser: state.user
    }
};

const validate = (formValues) => {
    const errors = {};

    if (!formValues.content) {
        errors.content = 'Message cannot be empty!';
    }

    return errors;
};

const formWrapped = reduxForm({
    form: 'profileMessageForm',
    validate: validate
})(userProfile);

export default connect(
    mapStateToProps,
    {userProfile, galleryUser, sendPrivateMessage, likeUserProfile, unlikeUserProfile, loggedUserData, doesConversationExist}
)(formWrapped);
